<template>
  <header class="p-4 mb-10 text-center bg-blue-800 text-gray-50">
    <div class="text-3xl font-bold md:text-5xl"><i class="fa fa-viruses"></i> Covid-19 Tracker</div>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>
